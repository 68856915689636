import { useState, useEffect } from 'react';
import FlyImage from './FlyImage';
import FlyError from './FlyError';
import flyover from '../../flyover';
import { roundUp } from '../../screens/Connect';
import { ethers } from '@rsksmart/bridges-core-sdk';

interface QrCodeProps {
  address: string;
  amount: bigint;
  blockchain: string;
}

function QrCode({ address, amount, blockchain }: QrCodeProps) {
  const [qrCode, setQrCode] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    (async () => {
      try {
        const qrCodeImage = await flyover.generateQrCode(
          address,
          roundUp(ethers.utils.formatEther(amount)).toString(),
          blockchain,
        );
        setQrCode(qrCodeImage);
      } catch (error) {
        setErrorMessage(
          error instanceof Error
            ? `QrCode: ${error.message}`
            : 'QrCode: Cannot get qr-code image',
        );
      }
    })();
  }, [address, amount, blockchain]);

  return (
    <>
      {qrCode && (
        <FlyImage
          src={qrCode}
          alt="Deposit address qr-code"
          sx={{ width: '100%', maxWidth: '15rem', borderRadius: '10px' }}
        />
      )}
      <FlyError errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
    </>
  );
}

export default QrCode;
