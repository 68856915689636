import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Quote,
  PegoutQuote,
  AcceptedQuote,
  AcceptedPegoutQuote,
  LiquidityProvider,
  DepositEvent,
} from '@rsksmart/flyover-sdk';
import { PegMode } from '../../types';
import defaultLPs from './defaultLPs';
import { loadUserQuotes } from './flyover.actions';

export interface FlyoverState {
  pegMode: PegMode;
  lpList: LiquidityProvider[];
  lpIndex: number;
  quoteReturn: (Quote | PegoutQuote)[];
  acceptedQuote?: AcceptedQuote | AcceptedPegoutQuote;
  captchaToken: string;
  userQuotes: DepositEvent[];
}

const initialState: FlyoverState = {
  pegMode: PegMode.PegIn,
  lpList: defaultLPs,
  lpIndex: 0,
  quoteReturn: [],
  userQuotes: [],
  captchaToken: '',
};

export const flyoverSlice = createSlice({
  name: 'flyover',
  initialState,
  reducers: {
    setPegMode: (state, { payload }: PayloadAction<PegMode>) => {
      state.pegMode = payload;
    },
    setLpList: (state, { payload }: PayloadAction<LiquidityProvider[]>) => {
      state.lpList = payload;
    },
    setLpIndex: (state, { payload }: PayloadAction<number>) => {
      state.lpIndex = isNaN(payload) || payload < 0 ? 0 : payload;
    },
    setQuoteReturn: (
      state,
      { payload }: PayloadAction<(Quote | PegoutQuote)[]>,
    ) => {
      state.quoteReturn = payload;
    },
    setAcceptedQuote: (
      state,
      { payload }: PayloadAction<AcceptedQuote | AcceptedPegoutQuote>,
    ) => {
      state.acceptedQuote = payload;
    },
    clearQuotes: (state) => {
      state.acceptedQuote = undefined;
      state.quoteReturn = [];
    },
    setCaptchaToken: (state, { payload }: PayloadAction<string>) => {
      state.captchaToken = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadUserQuotes.pending, () => {
      console.log('loadUserQuotes pending');
    });
    builder.addCase(loadUserQuotes.fulfilled, (state, { payload }) => {
      console.log('loadUserQuotes fulfilled');
      state.userQuotes = payload;
    });
    builder.addCase(loadUserQuotes.rejected, (state, action) => {
      console.log('loadUserQuotes rejected', action.error);
    });
  },
});

export default flyoverSlice;
