import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PaletteMode } from '@mui/material';

interface UiSlice {
  isDevMode: boolean;
  paletteMode: PaletteMode;
}

const initialState: UiSlice = {
  isDevMode: false,
  paletteMode: 'light',
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleDevInfo: (state) => {
      state.isDevMode = !state.isDevMode;
    },
    setColorMode: (state, { payload }: PayloadAction<PaletteMode>) => {
      state.paletteMode = payload;
      localStorage.setItem('paletteMode', payload);
    },
    toggleColorMode: (state) => {
      const newPaletteMode = state.paletteMode === 'dark' ? 'light' : 'dark';
      state.paletteMode = newPaletteMode;
      localStorage.setItem('paletteMode', newPaletteMode);
    },
  },
});
