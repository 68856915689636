import { Box } from '@mui/material';
import ColorModeSwitch from './UI/ColorModeSwitch';
import RifArrow from './UI/RifArrow';
import DisconnectButton from './UI/DisconnectButton';
import GoBackDialog from './UI/GoBackDialog';
import useLogout from '../hooks/useLogout';
import { useNavigate } from 'react-router-dom';

function Header() {
  const { openDialog, setOpenDialog, goBack} = useLogout();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/getQuote');
  };
  return (
    <>
      <Box
        sx={{
          height: '5rem',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box sx={{ flexGrow: 2 }}>
          <RifArrow
            onClick={handleClick}
            sx={{ width: 'fit-content', cursor: 'pointer' }}
          />
        </Box>
        <Box
          sx={{
            height: 'fit-content',
            display: 'flex',
            gap: '2rem',
            alignItems: 'center',
          }}
        >
          <ColorModeSwitch />
          <DisconnectButton />
        </Box>
      </Box>
      <GoBackDialog open={openDialog} setOpen={setOpenDialog} goBack={goBack} />
    </>
  );
}

export default Header;
