import { styled, Button } from '@mui/material';

const FlyButton = styled(Button)`
  padding: 0.8rem 2.5rem;
  border-radius: 8px;
  font-family: 'DM Mono';
  font-weight: 500;
  font-size: 1.1rem;
  text-transform: none;
  color: ${({ theme }) =>
    ({ dark: '#000', light: '#fff' }[theme.palette.mode])};
  background-color: ${({ theme }) =>
    ({ dark: '#fff', light: '#000' }[theme.palette.mode])};
  &:hover {
    background-color: ${({ theme }) =>
      ({ dark: '#fff', light: '#000' }[theme.palette.mode])};
  }
`;

export default FlyButton;
