import { DepositEvent } from '@rsksmart/flyover-sdk';
import { Box, Divider, Typography, styled } from '@mui/material';
import { ethers } from '@rsksmart/bridges-core-sdk';

const Text = styled(Typography)({
  fontSize: '0.7rem',
  fontWeight: 300,
  textAlign: 'left',
  '@media (max-width: 600px)': {
    fontSize: '0.5rem',
  },
});
const Label = styled(Typography)({
  fontSize: '0.8rem',
  fontWeight: 600,
  textAlign: 'left',
  '@media (max-width: 600px)': {
    fontSize: '0.6rem',
  },
});
const Line = styled(Box)({
  marginBottom: '0.4rem',
  overflowWrap: 'anywhere',
});

function UserQuote(
  deposit: DepositEvent,
  index: number,
  arrayLength: number,
) {
  if (!deposit.quoteHash || !deposit.amount) return null;
  return (
      <Box key={deposit.quoteHash}>
        <Box>
          <Line>
            <Label>Hash:</Label>
            <Text>{deposit.quoteHash}</Text>
          </Line>
          {deposit.from && deposit.from.length > 0 && (
            <Line>
              <Label>From:</Label>
              <Text>{deposit.from}</Text>
            </Line>
          )}
          <Line>
            <Label>Amount:</Label>
            <Text>{ethers.utils.formatEther(deposit.amount.toString())}</Text>
          </Line>
        </Box>
        {index !== arrayLength - 1 && <Divider sx={{ margin: '1rem 0' }} />}
      </Box>
  )
}

export default UserQuote;
