import { ReactNode, useCallback } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Link as MuiLink,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import SectionPaper from './UI/SectionPaper';
import { Path, PegMode } from '../types';
import useStore from '../hooks/useStore';

// list item: circle icon + title text
function StepItem({ path, children }: { path: Path; children: ReactNode }) {
  const location = useLocation();

  // checks if current browser location includes specific path
  const includes = useCallback((location: string, path: Path): boolean => {
    // take first part of a path (a single word)
    const regexResult = path.match(/[\w-]+/);
    if (!regexResult) return false;
    return location.includes(regexResult[0]);
  }, []);

  return (
    <ListItem disablePadding sx={{ width: 'fit-content' }}>
      <ListItemIcon
        sx={{
          minWidth: '1.4rem',
        }}
      >
        <CircleIcon
          sx={{
            width: '12px',
            color: includes(location.pathname, path)
              ? (theme) => theme.flyOver.persianBlue
              : (theme) => theme.flyOver.white,
          }}
        />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            sx={{
              '@media (max-width: 600px)': {
                fontSize: '0.8rem',
              },
              fontWeight: 600,
              ...(!includes(location.pathname, path)
                ? { color: (theme) => theme.flyOver.silverChalice }
                : {}),
            }}
          >
            {children}
          </Typography>
        }
      />
    </ListItem>
  );
}

function Steps() {
  const { flyover } = useStore();
  return (
    <SectionPaper
      sx={{
        flexBasis: 'fit-content',
        flexGrow: 0,
        paddingRight: '5rem',
        '@media (max-width: 1000px)': {
          paddingRight: '1rem',
        },
      }}
      heading="Steps"
    >
      <Typography
        sx={{
          margin: '-1.5rem 0 3rem 0',
          '@media (max-width: 1000px)': {
            margin: '-0.7rem 0 1rem 0',
          },
          color: (theme) => theme.flyOver.suva,
        }}
      >
        {flyover.pegMode === PegMode.PegIn ? 'BTC to RBTC' : 'RBTC to BTC'}
      </Typography>
      <List
        sx={{
          '@media (max-width: 1000px)': {
            display: 'flex',
            gap: '2rem',
            padding: 0,
          },
          '@media (max-width: 600px)': {
            gap: '1rem',
          },
        }}
      >
        <StepItem path={Path.getQuote}>Get a quote</StepItem>
        <StepItem path={Path.accept}>Accept a quote</StepItem>
        <StepItem path={Path.deposit}>Deposit</StepItem>
      </List>
      <MuiLink to={Path.userQuotes} component={RouterLink}>
        <Typography
          sx={{
            marginTop: '1rem',
          }}
        >
          My quotes
        </Typography>
      </MuiLink>
    </SectionPaper>
  );
}

export default Steps;
