import { Box, BoxProps } from '@mui/material';
import FlyImage from './FlyImage';
import rifArrow from '../../images/rif-arrow.svg';

function RifArrow(props: BoxProps) {
  return (
    <Box {...props}>
      <FlyImage
        src={rifArrow}
        alt="RIF"
        sx={{
          width: '5rem',
          filter: (theme) =>
            `invert(${theme.palette.mode === 'dark' ? 100 : 0}%)`,
        }}
      />
    </Box>
  );
}

export default RifArrow;
