import Backdrop, { BackdropProps } from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export function Segue(props: BackdropProps) {
  return (
    <Backdrop
      sx={{
        color: (theme) => theme.flyOver.white,
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      {...props}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default Segue;
