import { createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import {
  ethers,
  BlockchainConnection,
  Network,
} from '@rsksmart/bridges-core-sdk';
import RLogin from '@rsksmart/rlogin';
import { rLoginOptions } from '../../rlogin';
import flyover from '../../flyover';
import { RootState } from '../store';

async function connectFlyover(env: string) {
  flyover.setNetwork(env as Network);
  const rsk = await BlockchainConnection.createUsingStandard(window.ethereum);
  flyover.connectToRsk(rsk);
}

function addWeb3ProviderEventListeners(
  provider: ethers.providers.Web3Provider,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<any, any, any>,
) {
  const logout = () => {
    dispatch({ type: 'web3/logout' });
  };
  provider.on('chainChanged', logout);
  provider.on('disconnect', logout);
  provider.on('accountsChanged', logout);
}

export const login = createAsyncThunk('web3/login', async (arg, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  try {
    // prevent from connecting rLogin before the app disconnects
    if (state.web3.account) return;
    const defaultTheme = state.ui.paletteMode;
    const rLogin = new RLogin({
      ...rLoginOptions,
      defaultTheme,
    });
    const response = await rLogin.connect();
    const rLoginProvider = response.provider;
    const web3Provider = new ethers.providers.Web3Provider(rLoginProvider);
    const [walletAddress] = await web3Provider.listAccounts();
    const { chainId } = await web3Provider.getNetwork();
    if(!process.env.REACT_APP_ENV) throw new Error('Environment variable REACT_APP_ENV is not defined.');
    await connectFlyover(process.env.REACT_APP_ENV);
    addWeb3ProviderEventListeners(rLoginProvider, thunkApi.dispatch);
    return {
      chainId,
      account: walletAddress,
    };
  } catch (error) {
    thunkApi.dispatch({ type: 'web3/logout' });
  }
});
