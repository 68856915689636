import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { Provider as ReduxProvider } from 'react-redux';
import UiModeProvider from './providers/UiModeProvider';
import TimerProvider from './providers/TimerProvider';
import App from './App';
import store from './store/store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ReduxProvider store={store}>
        <TimerProvider>
          <UiModeProvider>
            <CssBaseline />
            <App />
          </UiModeProvider>
        </TimerProvider>
      </ReduxProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
