import React from 'react';
import { Tooltip, IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

interface RefreshButtonProps {
  onClick: () => void;
}
function RefreshButton({ onClick }: RefreshButtonProps) {
  return (
    <Tooltip title="Reload">
      <IconButton onClick={onClick}>
        <RefreshIcon
          sx={{
            height: '2rem',
            width: '2rem',
          }}
        />
      </IconButton>
    </Tooltip>
  );
}

export default RefreshButton;
