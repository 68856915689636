import { TextField, styled } from '@mui/material';

const FlyInput = styled(TextField)(({ theme }) => ({
  padding: '0.8rem',
  borderRadius: '10px',
  width: '100%',
  backgroundColor:
    theme.palette.mode === 'light'
      ? theme.flyOver.white
      : theme.flyOver.ecliple,

  '& .MuiInputBase-root': {
    marginTop: '1.8rem',
    fontSize: '0.9rem',
    color:
      theme.palette.mode === 'light'
        ? theme.flyOver.romanPurple
        : theme.flyOver.whiteout,
  },
  '& .MuiInputLabel-root': {
    left: '0.8rem',
    top: '0.8rem',
    fontFamily: 'IBM-Plex-semibold',
    color:
      theme.palette.mode === 'light'
        ? theme.flyOver.gentleGrape
        : theme.flyOver.fortress,
  },
  '&:focus-within': {
    boxShadow: `0 0 0 1px ${theme.flyOver.panPurple}`,
  },
  transition: 'box-shadow 0.2s ease-in-out',
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
}));

export default FlyInput;
