import React from 'react';
import { styled, Box, Stack, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FlyInput from './FlyInput';

const ButtonBox = styled(Stack)({
  position: 'absolute',
  right: '0.8rem',
  bottom: '0.8rem',
});

const ArrowButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  color:
    theme.palette.mode === 'light'
      ? theme.flyOver.gentleGrape
      : theme.flyOver.fortress,
}));

interface IFlyInputProps {
  title: string;
  value: number;
  handleChange: React.Dispatch<React.SetStateAction<number>>;
  max: number;
  min: number;
}

const step = 0.001;

function InputNumber({ title, value, handleChange, max, min }: IFlyInputProps) {
  /* 
  Quickly cycles through numbers on mouse press and hold
  */
  const interval = React.useRef<number | null>(null);
  // flag showing if button is pressed or released
  const pressed = React.useRef<boolean>(false);
  // starts iterating numbers after waiting 300 ms
  const startCount = (action: () => void) => {
    pressed.current = true;
    setTimeout(() => {
      if (interval.current || !pressed.current) return;
      interval.current = window.setInterval(action, 100);
    }, 300);
  };
  
  const stopCount = () => {
    if (interval.current == null) return;
    window.clearInterval(interval.current);
    interval.current = null;
    pressed.current = false;
  };
  const increment = () => {
    handleChange((prev) => {
      const newVal = prev + step;
      return newVal >= max ? max : +newVal.toFixed(3);
    });
  };
  const decrement = () => {
    handleChange((prev) => {
      const newVal = prev - step;
      return newVal <= min ? min : +newVal.toFixed(3);
    });
  };
  return (
    <Box position="relative">
      <FlyInput
        type="number"
        autoComplete="off"
        variant="standard"
        spellCheck={false}
        value={value}
        required
        onChange={(e) => handleChange(+e.target.value)}
        label={title}
        placeholder="Type here..."
        InputProps={{
          disableUnderline: true,
        }}
        inputProps={{ max, min, step }}
      />
      {typeof value === 'number' && (
        <ButtonBox>
          <ArrowButton
            onClick={() => {
              increment();
              pressed.current = false;
            }}
            onMouseDown={() => startCount(increment)}
            onMouseUp={stopCount}
            onMouseLeave={stopCount}
          >
            <KeyboardArrowUpIcon />
          </ArrowButton>
          <ArrowButton
            onClick={() => {
              decrement();
              pressed.current = false;
            }}
            onMouseDown={() => startCount(decrement)}
            onMouseUp={stopCount}
            onMouseLeave={stopCount}
          >
            <KeyboardArrowDownIcon />
          </ArrowButton>
        </ButtonBox>
      )}
    </Box>
  );
}

export default InputNumber;
