import { useState } from 'react';
import { Stack, Snackbar } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FlyAlert from './FlyAlert';

interface ICopyButtonProps {
  message: string;
}
function CopyButton({ message }: ICopyButtonProps) {
  const [alertOpen, setAlertOpen] = useState<boolean>(false);

  const copyToClipboard = () => {
    if (!message) return;
    navigator.clipboard.writeText(message.trim());
    setAlertOpen(true);
  };

  const closeAlert = () => {
    setAlertOpen(false);
  };

  return (
    <>
      <Stack
        sx={{
          flexBasis: '4rem',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
        onClick={copyToClipboard}
      >
        <ContentCopyIcon
          sx={{
            width: '1.1rem',
            color: (theme) =>
              theme.palette.mode === 'light'
                ? theme.flyOver.byzantine
                : theme.flyOver.white,
          }}
        />
      </Stack>
      <Snackbar open={alertOpen} onClose={closeAlert} autoHideDuration={3000}>
        <FlyAlert severity="success">Copied to clipboard</FlyAlert>
      </Snackbar>
    </>
  );
}

export default CopyButton;
