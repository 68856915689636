import { useTheme, SwitchProps } from '@mui/material';
import FlySwitch from './FlySwitch';
import sunImage from '../../images/sun.svg';
import moonImage from '../../images/moon.svg';
import useActions from '../../hooks/useActions';

function ColorModeSwitch(props: SwitchProps) {
  const theme = useTheme();
  const { toggleColorMode } = useActions();
  return (
    <FlySwitch
      checked={theme.palette.mode === 'dark'}
      onChange={() => toggleColorMode()}
      switchcolor="colorMode"
      thumbimage={{ checked: sunImage, unchecked: moonImage }}
      {...props}
    />
  );
}

export default ColorModeSwitch;
