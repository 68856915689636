import { Paper, Typography, styled, Stack } from '@mui/material';
import CallMadeIcon from '@mui/icons-material/CallMade';
import { PegMode } from '../../types';

interface IPegInOutButton {
  isactive: number;
  mode: PegMode;
}
const StyledPaper = styled(Paper)<IPegInOutButton>(({ theme, isactive }) => ({
  // trick to make the element square and scalable
  width: '13rem',
  '&:after': {
    content: '""',
    display: 'block',
    paddingTop: '100%',
  },
  position: 'relative',

  borderRadius: '10px',
  cursor: 'pointer',
  backgroundColor: isactive
    ? theme.flyOver.panPurple
    : theme.palette.mode === 'light'
    ? theme.flyOver.gainsboro
    : theme.flyOver.ecliple,
}));
const Container = styled(Stack)<IPegInOutButton>(({ mode }) => {
  return {
    padding: '1rem',
    position: 'absolute',
    width: '100%',
    height: '100%',
    flexDirection: mode === PegMode.PegOut ? 'row' : 'column',
    justifyContent: 'space-between',
  };
});
const Arrow = styled(CallMadeIcon)<IPegInOutButton>(
  ({ theme, isactive, mode }) => ({
    width: '70%',
    height: '70%',
    margin: '-13%',
    color: isactive
      ? theme.flyOver.whiteout
      : theme.palette.mode === 'light'
      ? theme.flyOver.whiteout
      : theme.flyOver.suva,
    transform: mode === PegMode.PegOut ? '' : 'scale(-1, -1)',
  }),
);
const Text = styled(Typography)<IPegInOutButton>(({ theme, isactive }) => ({
  fontWeight: 600,
  color: isactive
    ? theme.flyOver.whiteout
    : theme.palette.mode === 'light'
    ? theme.flyOver.whiteout
    : theme.flyOver.suva,
}));

function PegInOutButton(props: Parameters<typeof StyledPaper>[0]) {
  const { children, mode, isactive } = props;
  return (
    <StyledPaper elevation={0} {...props}>
      <Container mode={mode} isactive={isactive}>
        <Text isactive={isactive} mode={mode}>
          {children}
        </Text>
        <Arrow isactive={isactive} mode={mode} />
      </Container>
    </StyledPaper>
  );
}
export default PegInOutButton;
