import type { CSSProperties } from 'react';
import Sora from '../fonts/Sora.ttf';
import IbmPlexSemi from '../fonts/IBMPlexSans-SemiBold.ttf';
import IbmPlexReg from '../fonts/IBMPlexSans-Regular.ttf';
import DMMono from '../fonts/DMMono-Regular.ttf';
import { PaletteMode, createTheme, Theme } from '@mui/material';

export interface FlyoverColors {
  ecliple: CSSProperties['color'];
  white: CSSProperties['color'];
  patence: CSSProperties['color'];
  panPurple: CSSProperties['color'];
  codGray: CSSProperties['color'];
  bazalt: CSSProperties['color'];
  whiteSmoke: CSSProperties['color'];
  gainsboro: CSSProperties['color'];
  gentleGrape: CSSProperties['color'];
  romanPurple: CSSProperties['color'];
  byzantine: CSSProperties['color'];
  gorgonzola: CSSProperties['color'];
  superSilver: CSSProperties['color'];
  fiftiethShade: CSSProperties['color'];
  eerie: CSSProperties['color'];
  nero: CSSProperties['color'];
  fortress: CSSProperties['color'];
  whiteout: CSSProperties['color'];
  shadowMountain: CSSProperties['color'];
  suva: CSSProperties['color'];
  silverChalice: CSSProperties['color'];
  persianBlue: CSSProperties['color'];
  black: CSSProperties['color'];
}

/* 
Extends MUI theme:
- adds custom colors for dark & light theme modes
- changes font to Sora
*/

export function makeTheme(mode: PaletteMode): Theme {
  const theme = createTheme({
    flyOver: {
      ecliple: '#3A3A3A',
      white: '#FFFFFF',
      patence: '#E6DED6',
      panPurple: '#6170F2',
      codGray: '#121212',
      bazalt: '#999999',
      whiteSmoke: '#F1F1F1',
      gainsboro: '#DBDBDB',
      gentleGrape: '#90889A',
      romanPurple: '#544864',
      byzantine: '#7683F4',
      gorgonzola: '#4250CC',
      superSilver: '#F1F1F1',
      fiftiethShade: '#4f4f4f',
      eerie: '#1E1E1E',
      nero: '#252525',
      fortress: '#B8B8B8',
      whiteout: '#FBFBFB',
      shadowMountain: '#575757',
      suva: '#898989',
      silverChalice: '#A9A9A9',
      persianBlue: '#4B5CF0',
      black: '#000000',
    },
    palette: {
      mode,
    },
    typography: {
      fontFamily: 'Sora',
    },
  });

  return createTheme(theme, {
    components: {
      MuiTypography: {
        defaultProps: {
          color: mode === 'light' ? theme.flyOver.codGray : theme.flyOver.white,
        },
      },
      MuiCssBaseline: {
        styleOverrides: `
          body {
            background-color: ${
              mode === 'dark' ? theme.flyOver.eerie : theme.flyOver.white
            };
          }
          img {
            width: 100%;
          }
          @font-face {
            font-family: 'Sora';
            src: url(${Sora}) format('truetype');
          }
          @font-face {
            font-family: 'IBM-Plex-regular';
            src: url(${IbmPlexReg}) format('truetype');
          }
          @font-face {
            font-family: 'IBM-Plex-semibold';
            src: url(${IbmPlexSemi}) format('truetype');
          }
          @font-face {
            font-family: 'DM Mono';
            src: url(${DMMono}) format('truetype');
          }
        `,
      },
    },
  });
}

export default makeTheme;
