import { Box, Typography, Link } from '@mui/material';

function Footer() {
  return (
    <Box
      sx={{
        paddingTop: '1rem',
        textAlign: 'center',
      }}
    >
      <Typography
        variant="body2"
        sx={{
          fontFamily: 'IBM-Plex-semibold',
          color: (theme) => theme.flyOver.bazalt,
        }}
      >
        Want to integrate Flyover into your own product or protocol?
        <Link
          href="https://www.npmjs.com/package/@rsksmart/flyover-sdk"
          target="blank"
          sx={{
            fontFamily: 'IBM-Plex-semibold',
            color: (theme) => theme.flyOver.bazalt,
          }}
        >
          {' '}
          Download the SDK
        </Link>
      </Typography>
    </Box>
  );
}

export default Footer;
