import { LiquidityProvider } from '@rsksmart/flyover-sdk';

const defaultLPs: LiquidityProvider[] = [
  {
    id: 1,
    provider: '0xd053b9B695BEb7104deEa56773197F05AD03E4e0',
    name: 'Loading providers...',
    siteKey: '6LfmHT4oAAAAAD_gQJJUVu6uQRtbkmvA5iKCdtqF',
    pegin: {
      fee: 100000000000000n,
      minTransactionValue: 5000000000000000n,
      maxTransactionValue: 1000000000000000000n,
      requiredConfirmations: 1,
    },
    pegout: {
      fee: 1000000000000000n,
      minTransactionValue: 0n,
      maxTransactionValue: 1000000000000000000n,
      requiredConfirmations: 1,
    },
    apiBaseUrl: 'https://lps.testnet.flyover.rif.technology',
    status: true,
    providerType: 'both',
    liquidityCheckEnabled: false,
  },
];

export default defaultLPs;
