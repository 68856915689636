import { Box, Stack, Typography } from '@mui/material';
import RifArrow from '../components/UI/RifArrow';
import ColorModeSwitch from '../components/UI/ColorModeSwitch';
import FlyButtonBlack from '../components/UI/FlyButtonBlack';
import RocketMosaic from '../components/UI/RocketMosaic';
import useActions from '../hooks/useActions';
import Layout from '../components/Layout';

export const roundUp = (numberString: string) => {
  const scale = Math.pow(10, 8);
  return Math.ceil(Number(numberString) * scale) / scale;
};
function Connect() {
  const { login } = useActions();
  return (
    <Layout
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'row',
        '@media (max-width: 600px)': {
          flexDirection: 'column-reverse',
        },
        position: 'relative',
      }}
    >
      <Stack flexBasis="50%">
        <Box
          sx={{
            flexBasis: '50%',
            '@media (max-width: 600px)': {
              flexBasis: '20%',
            },
          }}
        >
          <RifArrow
            sx={{
              position: 'absolute',
              left: '2vw',
              top: '2vw',
              zIndex: 100,
            }}
          />
        </Box>
        <Stack
          sx={{
            flexBasis: '40%',
            padding: '0 15% 3rem 5%',

            justifyContent: 'space-between',
            '@media (max-width: 600px)': {
              padding: '0 5% 2% 5%',
              flexBasis: '80%',
            },
          }}
        >
          <Typography variant="h1" fontWeight={600}>
            Flyover
          </Typography>
          <Typography paragraph variant="body2" fontWeight={700}>
            Safely and securely transfer BTC between the Bitcoin mainchain and
            the Rootstock sidechain with RIF Flyover.
          </Typography>
          <FlyButtonBlack onClick={login}>CONNECT YOUR WALLET</FlyButtonBlack>
        </Stack>
      </Stack>
      <Stack overflow="hidden" flexBasis="60%">
        <ColorModeSwitch
          sx={{ position: 'absolute', right: '2vw', top: '2vw', zIndex: 100 }}
        />
        <RocketMosaic />
      </Stack>
    </Layout>
  );
}

export default Connect;
