import { useEffect } from 'react';
import useStore from '../hooks/useStore';
import lightFavicon from '../images/favicon-light.svg';
import darkFavicon from '../images/favicon-dark.svg';

function useFavicon() {
  const {
    ui: { paletteMode },
  } = useStore();

  useEffect(() => {
    const favicon = document.getElementById('favicon') as HTMLLinkElement;
    if (paletteMode === 'light') favicon.href = lightFavicon;
    else favicon.href = darkFavicon;
  }, [paletteMode]);
}

export default useFavicon;
