import { useMemo, useContext, useEffect } from 'react';
import { LinearProgress, Box, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { TimerContext } from '../../providers/TimerProvider';

function getTime(time: number): { minutes: number; seconds: number } {
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;
  return { minutes, seconds };
}

function Timer() {
  const { time, totalSeconds, runTimer } = useContext(TimerContext);
  const normalizedTime = useMemo<number>(
    () => (time * 100) / totalSeconds,
    [time, totalSeconds],
  );

  useEffect(() => {
    runTimer();
  }, [runTimer]);

  return (
    <Box sx={{ textAlign: 'center', paddingTop: '1rem' }}>
      <LinearProgress
        variant="determinate"
        value={normalizedTime}
        sx={{
          height: '5px',
          borderRadius: '2px',
          backgroundColor: (theme) => theme.flyOver.gainsboro,
          '& .MuiLinearProgress-bar': {
            backgroundColor: (theme) => theme.flyOver.byzantine,
          },
        }}
      />
      <Typography
        variant="body2"
        sx={{
          fontWeight: 500,
          marginTop: '0.8rem',
        }}
      >
        {time ? (
          <>
            You have
            <AccessTimeIcon
              sx={{
                margin: '0 0.1rem -0.2rem 0.3rem',
                width: '1.1rem',
                height: '1.1rem',
              }}
            />
            <strong>{`${
              getTime(time).minutes > 0 ? `${getTime(time).minutes}m` : ''
            } ${getTime(time).seconds}s `}</strong>
            to make the deposit
          </>
        ) : (
          <>Time&apos;s up. Get another quote</>
        )}
      </Typography>
    </Box>
  );
}

export default Timer;
