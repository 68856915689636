import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, CircularProgress, Box } from '@mui/material';
import SectionContainer from '../components/UI/SectionContainer';
import SectionPaper from '../components/UI/SectionPaper';
import FlyButton from '../components/UI/FlyButton';
import FlyError from '../components/UI/FlyError';
import JsonPaper from '../components/UI/JsonPaper';
import UserQuote from '../components/UI/UserQuote';
import RefreshButton from '../components/UI/RefreshButton';
import useStore from '../hooks/useStore';
import useActions from '../hooks/useActions';
function UserQuotes() {
  const navigate = useNavigate();
  const {
    web3: { account },
    ui: { isDevMode },
    flyover: { userQuotes },
    flyover: { lpList, lpIndex },
  } = useStore();
  const { loadUserQuotes } = useActions();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const load = async () => {
    if (!account) return;
    setLoading(true);
    await loadUserQuotes({
      address: account,
      lpIndex: lpIndex,
      lpList: lpList,
    });
    setLoading(false);
  };

  useEffect(() => {
    load();
  }, []);
  return (
    <>
      <SectionPaper heading="Your previous quotes">
        <SectionContainer spacing={2}>
          <Box
            sx={{
              textAlign: 'center',
              minHeight: '3rem',
            }}
          >
            {account && (
              <>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {userQuotes.length === 0 ? (
                      <>
                        <Typography variant="h6">
                          You don&apos;t have any quotes yet
                        </Typography>
                        <RefreshButton onClick={load} />
                      </>
                    ) : (
                      <Box>{userQuotes.map((deposit, index) => UserQuote(deposit, index, userQuotes.length))}</Box>
                    )}
                  </Box>
                )}
              </>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <FlyButton
              type="button"
              onClick={() => navigate(-1)}
              sx={{
                flexGrow: 2,
              }}
            >
              Back
            </FlyButton>
            {userQuotes.length > 0 && <RefreshButton onClick={load} />}
          </Box>
        </SectionContainer>
      </SectionPaper>
      {isDevMode && (
        <SectionPaper heading="Dev Info">
          <SectionContainer spacing={2}>
            {userQuotes.length > 0 && (
              <JsonPaper title="User Quotes" value={userQuotes} />
            )}
          </SectionContainer>
        </SectionPaper>
      )}
      <FlyError
        errorMessage={errorMessage}
        setErrorMessage={() => setErrorMessage('')}
      />
    </>
  );
}

export default UserQuotes;
