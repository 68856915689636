import { createAsyncThunk } from '@reduxjs/toolkit';
import flyover from '../../flyover';
import { LiquidityProvider } from '@rsksmart/flyover-sdk';
export const loadUserQuotes = createAsyncThunk(
  'flyover/loadUserQuotes',
  async ({
    address,
    lpList,
    lpIndex,
  }: {
    address: string;
    lpList: LiquidityProvider[];
    lpIndex: number;
  }) => {
    flyover.useLiquidityProvider(lpList[lpIndex]);
    const response = await flyover.getUserQuotes(address);
    const stringifiedResponse = response.map((item) => {
      return Object.fromEntries(
        Object.entries(item).map(([key, value]) => [key, String(value)]),
      );
    });
    console.log(stringifiedResponse);
    return stringifiedResponse;
  },
);
