import React from 'react';
import { Box } from '@mui/material';
import FlyInput from './FlyInput';

interface IFlyInputProps {
  title: string;
  value: string;
  handleChange: React.Dispatch<React.SetStateAction<string>>;
}

function InputText({ title, value, handleChange }: IFlyInputProps) {
  return (
    <Box position="relative">
      <FlyInput
        type="text"
        autoComplete="off"
        variant="standard"
        spellCheck={false}
        value={value}
        required
        onChange={(e) => handleChange(e.target.value)}
        label={title}
        placeholder="Type here..."
        InputProps={{
          disableUnderline: true,
        }}
      />
    </Box>
  );
}

export default InputText;
