import { PaperProps, useTheme, Typography } from '@mui/material';
import { JsonViewer } from '@textea/json-viewer';
import InfoCardPaper from './InfoCardPaper';

interface IJsonPaperProps {
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}
function JsonPaper({ value, title }: IJsonPaperProps & PaperProps) {
  const theme = useTheme();
  return (
    <InfoCardPaper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.8rem',
      }}
    >
      <Typography
        variant="caption"
        sx={{
          fontFamily: 'IBM-Plex-semibold',
          color: (theme) =>
            theme.palette.mode === 'light'
              ? theme.flyOver.gentleGrape
              : theme.flyOver.fortress,
        }}
      >
        {title}
      </Typography>
      <JsonViewer
        sx={{ fontSize: '0.8rem', backgroundColor: 'transparent' }}
        value={value}
        theme={theme.palette.mode}
      />
    </InfoCardPaper>
  );
}

export default JsonPaper;
