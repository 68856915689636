import { Chip, styled } from '@mui/material';
import GoBackDialog from './GoBackDialog';
import useLogout from '../../hooks/useLogout';

const StyledButton = styled(Chip)(({ theme }) => {
  const isLight = theme.palette.mode === 'light';
  return {
    padding: '0.3rem 1rem',
    color: isLight ? theme.flyOver.eerie : theme.flyOver.white,
    backgroundColor: isLight ? theme.flyOver.white : theme.flyOver.eerie,
    borderColor: isLight ? theme.flyOver.gainsboro : theme.flyOver.white,
    borderWidth: '1px',
    borderStyle: 'solid',
  };
});

function DisconnectButton() {
  const { openDialog, setOpenDialog, checkPath, goBack } = useLogout();
  return (
    <>
      <StyledButton label="disconnect" onClick={checkPath} />
      <GoBackDialog open={openDialog} setOpen={setOpenDialog} goBack={goBack} />
    </>
  );
}

export default DisconnectButton;
