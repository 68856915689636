import { useEffect } from 'react';
import {
  Stack,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
  styled,
  FormControl,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { ethers } from '@rsksmart/bridges-core-sdk';
import useActions from '../../hooks/useActions';
import useStore from '../../hooks/useStore';

export const Heading = styled(Typography)(({ theme }) => ({
  fontFamily: 'IBM-Plex-semibold',
  color:
    theme.palette.mode === 'light'
      ? theme.flyOver.gentleGrape
      : theme.flyOver.fortress,
}));
export const Text = styled(Typography)(({ theme }) => ({
  color:
    theme.palette.mode === 'light'
      ? theme.flyOver.romanPurple
      : theme.flyOver.whiteout,
}));
export const HorizStack = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
});
export const FormContainer = styled(FormControl)(({ theme }) => ({
  padding: '0.7rem 0.8rem 0.2rem 0.8rem',
  backgroundColor:
    theme.palette.mode === 'light'
      ? theme.flyOver.white
      : theme.flyOver.ecliple,
  borderRadius: '10px',
  position: 'relative',
}));

function LpSelect() {
  const {
    flyover: { pegMode, lpIndex, lpList },
  } = useStore();
  const { setLpIndex } = useActions();

  // keep either only PegIn or PegOut providers
  const lps = lpList.filter(
    (lp) =>
      lp.providerType === pegMode.toLowerCase() || lp.providerType === 'both',
  );
  const isLpInList = () => lps?.some((lp) => lp?.id === lpList?.[lpIndex]?.id);

  useEffect(() => {
    // if selected provider is not in the current peg mode LP list
    if ((lpList?.length ?? 0) > 0 && !isLpInList())
      setLpIndex(lpList?.findIndex((lp) => lp?.id === lps?.[0]?.id) ?? 0);
  }, [pegMode]);

  const handleLpChange = (e: SelectChangeEvent<number>) => {
    setLpIndex(+e.target.value);
  };

  return (
    <FormContainer>
      <Stack gap="0.6rem">
        <HorizStack width="90%">
          <Heading variant="caption">Liquidity Provider</Heading>
          <Heading variant="caption">Fee, RBTC</Heading>
        </HorizStack>
        <Select
          required
          variant="standard"
          displayEmpty
          disableUnderline
          value={isLpInList() ? lpIndex : ''}
          onChange={handleLpChange}
          IconComponent={ExpandMore}
          sx={{
            '& .MuiSelect-icon': {
              transform: 'translateY(-1rem)',
              transition: 'transform 0.3s linear',
              color: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.flyOver.gentleGrape
                  : theme.flyOver.fortress,
            },
            '& .MuiSelect-iconOpen': {
              transform: 'translateY(-1rem) rotate(-180deg)',
            },
          }}
        >
          {lpList?.map(({ name, id, pegin, pegout }, i) => {
            return (
              <MenuItem key={id} value={i}>
                <HorizStack width="96%">
                  <Text variant="body2">{`${name}`}</Text>
                  <Text variant="body2" marginLeft="0.8rem">
                    {ethers.utils.formatEther(
                      pegMode.toLocaleLowerCase() === 'pegin'
                        ? pegin.fee
                        : pegout.fee,
                    )}
                  </Text>
                </HorizStack>
              </MenuItem>
            );
          })}
        </Select>
      </Stack>
    </FormContainer>
  );
}

export default LpSelect;
