import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import type { RootState } from '../store/store';

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

function useStore() {
  const flyover = useAppSelector((state) => state.flyover);
  const web3 = useAppSelector((state) => state.web3);
  const ui = useAppSelector((state) => state.ui);
  return useMemo(() => {
    return { flyover, web3, ui };
  }, [flyover, web3, ui]);
}

export default useStore;
