import { useState, FormEvent, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { PeginQuoteRequest } from '@rsksmart/flyover-sdk';
import { Path } from '../types';
import {
  assertIsAccount,
  assertIsLp,
  assertIsValidBtcAddress,
} from '../types/assertions';
import SectionContainer from '../components/UI/SectionContainer';
import SectionPaper from '../components/UI/SectionPaper';
import PegInOutButtons from '../components/UI/PegInOutButtons';
import FlyButton from '../components/UI/FlyButton';
import InfoCard from '../components/UI/InfoCard';
import LpSelect from '../components/UI/LpSelect';
import InputText from '../components/UI/InputText';
import InputNumber from '../components/UI/InputNumber';
import Segue from '../components/UI/Segue';
import FlyError from '../components/UI/FlyError';
import useStore from '../hooks/useStore';
import useActions from '../hooks/useActions';
import JsonPaper from '../components/UI/JsonPaper';
import flyover from '../flyover';
import { TimerContext } from '../providers/TimerProvider';
import { ethers, rskChecksum } from '@rsksmart/bridges-core-sdk';
import defaultLPs from '../store/flyover/defaultLPs';

function GetQuotePegIn() {
  const navigate = useNavigate();
  const { setQuoteReturn } = useActions();
  const {
    flyover: { lpList, lpIndex },
    web3: { account, chainId },
    ui: { isDevMode },
  } = useStore();
  const { setTimer } = useContext(TimerContext);

  const [rskRefundAddress, setRskRefundAddress] = useState<string>(
    account ?? '0x...',
  );
  const [btcRefundAddress, setBtcRefundAddress] = useState<string>(
    '',
  );
  const [toAddress, setToAddress] = useState<string>('0x...');

  const lp = lpList[lpIndex];
  // min and max values to transfer
  const [minVal, setMinVal] = useState(0);
  const [maxVal, setMaxVal] = useState(0);

  useEffect(() => {
    if (toAddress === '0x...' && account) {
      setToAddress(account);
    }
  }, [account, toAddress]);

  useEffect(() => {
    let newMinVal = 0;
    let newMaxVal = 0;
    try {
      newMinVal = Number(
        ethers.utils.formatEther(lp?.pegin.minTransactionValue ?? 0),
      );
      newMaxVal = Number(
        ethers.utils.formatEther(lp?.pegin.maxTransactionValue ?? 0),
      );
    } catch (error) {
      // do nothing
    }
    setMinVal(newMinVal);
    setMaxVal(newMaxVal);
  }, [lp]);
  const [valueToTransfer, setValueToTransfer] = useState<number>(minVal);

  useEffect(() => {
    setValueToTransfer(minVal);
  }, [minVal]);

  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleGetQuote = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(!chainId) return
    if (lpList[0] == defaultLPs[0]) {
      setErrorMessage('Providers still loading, try again in a second.');
      return;
    }
    try {
      assertIsAccount(account);
      assertIsLp(lp);
      if(!assertIsValidBtcAddress(btcRefundAddress,chainId)) throw new Error('Only bitcoin legacy and P2SH addresses are supported')
      setLoading(true);
      const quoteRequest: PeginQuoteRequest = {
        callEoaOrContractAddress: rskChecksum(toAddress, chainId),
        rskRefundAddress: rskChecksum(rskRefundAddress, chainId),
        bitcoinRefundAddress: btcRefundAddress,
        valueToTransfer: BigInt(
          ethers.utils.parseEther(valueToTransfer.toString()).toString(),
        ),
        callContractArguments: '',
      };
      flyover.useLiquidityProvider(lp);
      const quotes = await flyover.getQuotes(quoteRequest);
      setTimer(quotes[0].quote.timeForDeposit);
      setQuoteReturn(quotes);
      navigate(Path.accept);
    } catch (error) {
      setErrorMessage(
        error instanceof Error
          ? `GetQuotePegIn: ${error.message}`
          : 'GetQuotePegIn: Cannot get quote',
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SectionPaper heading="Get a quote">
        <Box component="form" onSubmit={handleGetQuote}>
          <SectionContainer spacing={2}>
            <PegInOutButtons />
            <LpSelect />
            <InputText
              title="To Address (rBTC)"
              value={toAddress}
              handleChange={setToAddress}
            />
            <InputText
              title="Wallet Address to get BTC Refund"
              value={btcRefundAddress}
              handleChange={setBtcRefundAddress}
            />
            <InputText
              title="Wallet Address to get RBTC Refund"
              value={rskRefundAddress}
              handleChange={setRskRefundAddress}
            />
            <InputNumber
              title={`Amount to Transfer (min: ${minVal}, max: ${maxVal} BTC)`}
              value={valueToTransfer}
              handleChange={setValueToTransfer}
              max={maxVal}
              min={minVal}
            />
            <FlyButton type="submit">Get a Quote and Continue</FlyButton>
          </SectionContainer>
        </Box>
      </SectionPaper>
      {isDevMode && (
        <SectionPaper heading="Dev Info">
          <SectionContainer spacing={2}>
            {account && (
              <InfoCard title="RBTC Wallet" message={account} hasCopy />
            )}
            {chainId && (
              <InfoCard title="Chain ID" message={chainId?.toString()} />
            )}
            {lp && 'id' in lp && (
              <JsonPaper title="Liquidity provider" value={lp} />
            )}
          </SectionContainer>
        </SectionPaper>
      )}
      <FlyError errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
      <Segue open={loading} />
    </>
  );
}

export default GetQuotePegIn;
