import { Paper, Typography, PaperProps, styled } from '@mui/material';

interface ISectionProps {
  heading: string;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  flexBasis: '1%',
  flexGrow: 2,
  padding: '1.5rem',
  backgroundColor:
    theme.palette.mode === 'light'
      ? theme.flyOver.whiteSmoke
      : theme.flyOver.nero,
  borderRadius: '10px',
  overflow: 'auto',
}));

function SectionPaper({
  heading,
  children,
  ...props
}: ISectionProps & PaperProps) {
  return (
    <StyledPaper {...props} elevation={0}>
      <Typography
        variant="h5"
        component="h2"
        sx={{
          marginBottom: '2rem',
          '@media (max-width: 1000px)': {
            marginBottom: '1rem',
          },
        }}
      >
        {heading}
      </Typography>
      {children}
    </StyledPaper>
  );
}

export default SectionPaper;
