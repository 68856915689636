import { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import useActions from './useActions';
import { Path } from '../types';
import { TimerContext } from '../providers/TimerProvider';

function useLogout() {
  const location = useLocation();
  const { logout, clearQuotes } = useActions();
  const [openDialog, setOpenDialog] = useState(false);
  const { resetTimer } = useContext(TimerContext);

  const checkPath = () => {
    if (location.pathname !== Path.deposit) return setOpenDialog(true);
    goBack();
  };

  const goBack = () => {
    resetTimer();
    clearQuotes();
    logout();
  };

  return { openDialog, setOpenDialog, checkPath, goBack };
}

export default useLogout;
