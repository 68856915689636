import { Typography, Stack } from '@mui/material';
import CopyButton from './CopyButton';
import InfoCardPaper from './InfoCardPaper';

interface IInfoCardProps {
  title: string;
  message?: string;
  hasCopy?: boolean;
}

function InfoCard({ message, title, hasCopy }: IInfoCardProps) {
  return (
    <>
      <InfoCardPaper
        sx={{
          padding: message ? '0.7rem 0 0.7rem 0.8rem' : '1.2rem 0',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Stack
          sx={{
            flexGrow: message ? 2 : 0,
            gap: '0.8rem',
            overflow: 'auto',
          }}
        >
          <Typography
            variant="caption"
            sx={{
              fontFamily: 'IBM-Plex-semibold',
              color: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.flyOver.gentleGrape
                  : theme.flyOver.fortress,
            }}
          >
            {title}
          </Typography>
          {message && (
            <>
              <Typography
                variant="body2"
                sx={{
                  color: (theme) =>
                    theme.palette.mode === 'light'
                      ? theme.flyOver.romanPurple
                      : theme.flyOver.whiteout,
                }}
              >
                {message}
              </Typography>
            </>
          )}
        </Stack>
        {message && hasCopy && <CopyButton message={message} />}
      </InfoCardPaper>
    </>
  );
}

export default InfoCard;
