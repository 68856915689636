import { createRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PegoutQuote, FlyoverError, FlyoverUtils } from '@rsksmart/flyover-sdk';
import { Path, MetamaskError } from '../types';
import { assertIsQuote } from '../types/assertions';
import SectionContainer from '../components/UI/SectionContainer';
import SectionPaper from '../components/UI/SectionPaper';
import InfoCard from '../components/UI/InfoCard';
import FlyButton from '../components/UI/FlyButton';
import Segue from '../components/UI/Segue';
import JsonPaper from '../components/UI/JsonPaper';
import FlyError from '../components/UI/FlyError';
import useStore from '../hooks/useStore';
import useActions from '../hooks/useActions';
import flyover from '../flyover';
import ReCAPTCHA from 'react-google-recaptcha';
import { ethers } from '@rsksmart/bridges-core-sdk';
import { roundUp } from './Connect';

export interface LocationState {
  txHash?: string;
}

function AcceptQuotePegOut() {
  const navigate = useNavigate();
  const {
    flyover: { quoteReturn, lpIndex, lpList },
    ui: { isDevMode },
  } = useStore();
  const { setAcceptedQuote, setCaptchaToken } = useActions();

  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const quote = quoteReturn[0] as PegoutQuote | undefined;
  const depositAmount = FlyoverUtils.getQuoteTotal(quote!);

  const recaptchaRef = createRef<ReCAPTCHA>();

  const acceptQuote = async () => {
    setLoading(true);
    recaptchaRef.current?.reset();
    recaptchaRef.current?.execute();
  };
  
  async function onChange(token: string | null) {
    if (!token) {
      setErrorMessage('No captcha token resolved');
      return;
    }
    try {
      assertIsQuote(quote);
      setCaptchaToken(token);
      const acceptedPegOutQuote = await flyover.acceptPegoutQuote(quote);
      const signature = acceptedPegOutQuote.signature;
      setAcceptedQuote(acceptedPegOutQuote);
      const txHash = await flyover.depositPegout(
        quote,
        signature,
        depositAmount,
      );
      setCaptchaToken(token);
      const state: LocationState = { txHash };
      navigate(Path.deposit, { state });
    } catch (error) {
      if (error instanceof FlyoverError) {
        const errorDetails: MetamaskError = error.details;
        const metamaskErrMsg = errorDetails.error ?? '';
        // don't show error message if user has cancelled a tx himself
        if (!/user rejected transaction/i.test(metamaskErrMsg))
          setErrorMessage(`AcceptPegOutQuote: ${error.message}`);
      } else if (error instanceof Error) {
        setErrorMessage(`AcceptPegOutQuote: ${error.message}`);
      } else {
        setErrorMessage('AcceptPegOutQuote: Cannot accept quote');
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={lpList[lpIndex].siteKey}
        onChange={onChange}
      />
      <SectionPaper heading="Accept a quote">
        {quote && (
          <SectionContainer spacing={2}>
            <InfoCard
              title="Amount to deposit (RBTC) = Amount to deposit + Call Fee"
              message={roundUp(ethers.utils.formatEther(depositAmount)).toString()}
              hasCopy
            />
            <InfoCard
              title="Number of block confirmations"
              message={quote?.quote?.depositConfirmations?.toString() ?? '0'}
              hasCopy
            />
            <InfoCard title="Quote Hash" message={quote?.quoteHash} hasCopy />
            <FlyButton onClick={acceptQuote}>Accept Quote & Continue</FlyButton>
          </SectionContainer>
        )}
      </SectionPaper>
      {isDevMode && (
        <SectionPaper heading="Dev Info">
          {quote && (
            <SectionContainer>
              <JsonPaper title="PegOut quote" value={quote} />
            </SectionContainer>
          )}
        </SectionPaper>
      )}
      <FlyError errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
      <Segue open={loading} />
    </>
  );
}

export default AcceptQuotePegOut;
