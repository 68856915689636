import { ReactNode, useMemo, useEffect } from 'react';
import {
  ThemeProvider as MuiThemeProvider,
  PaletteMode,
  useMediaQuery,
} from '@mui/material';
import { makeTheme } from './theme';
import useStore from '../hooks/useStore';
import useActions from '../hooks/useActions';
import useFavicon from '../hooks/useFavicon';

interface IUiModeProviderProps {
  children?: ReactNode;
}
export function UiModeProvider({ children }: IUiModeProviderProps) {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  useFavicon();
  const { setColorMode } = useActions();
  const {
    ui: { paletteMode },
  } = useStore();

  useEffect(() => {
    const storedPaletteMode = localStorage.getItem(
      'paletteMode',
    ) as PaletteMode | null;
    setColorMode(storedPaletteMode ?? (prefersDarkMode ? 'dark' : 'light'));
  }, [prefersDarkMode, setColorMode]);

  const theme = useMemo(() => makeTheme(paletteMode), [paletteMode]);

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}

export default UiModeProvider;
