import { AcceptedQuote, FlyoverUtils, Quote } from '@rsksmart/flyover-sdk';
import { Box, Typography } from '@mui/material';
import SectionContainer from '../components/UI/SectionContainer';
import SectionPaper from '../components/UI/SectionPaper';
import InfoCard from '../components/UI/InfoCard';
import JsonPaper from '../components/UI/JsonPaper';
import Timer from '../components/UI/Timer';
import useStore from '../hooks/useStore';
import QrCode from '../components/UI/QrCode';
import { ethers } from '@rsksmart/bridges-core-sdk';
import { roundUp } from './Connect';

function DepositPegIn() {
  const {
    flyover,
    ui: { isDevMode },
  } = useStore();
  const acceptedQuote = flyover.acceptedQuote as AcceptedQuote;
  const quote = flyover.quoteReturn[0] as Quote | undefined;
  const depositAmount = FlyoverUtils.getQuoteTotal(quote!);

  return (
    <>
      <SectionPaper heading="Deposit Funds">
        {acceptedQuote && (
          <Box
            sx={{
              display: 'flex',
              gap: '2rem',
              flexDirection: isDevMode ? 'column' : 'row',
              justifyContent: 'center',
              '@media (max-width: 1000px)': {
                flexDirection: 'row',
              },
              alignItems: isDevMode ? 'center' : 'flex-start',
              '@media (max-width: 800px)': {
                flexDirection: 'column',
                alignItems: 'center',
              },
            }}
          >
            <QrCode
              address={acceptedQuote?.bitcoinDepositAddressHash}
              amount={depositAmount}
              blockchain="bitcoin"
            />
            <SectionContainer spacing={2} sx={{ margin: 'unset' }}>
              <InfoCard
                title="Amount to deposit (BTC) = Amount to deposit + Call Fee"
                message={roundUp(
                  ethers.utils.formatEther(depositAmount),
                ).toString()}
                hasCopy
              />
              <InfoCard
                title="BTC Address"
                message={acceptedQuote?.bitcoinDepositAddressHash}
                hasCopy
              />
              <Timer />
            </SectionContainer>
          </Box>
        )}
        <Box
          gridArea="warning"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light' ? '#dbdbdb' : '#3A3A3A',
            padding: '1rem',
            marginTop: '1.5rem',
            borderRadius: '1rem',
          }}
        >
          <Typography sx={{ color: '#C1423F' }}>
            <Typography sx={{ fontSize: '1.5rem', color: '#C1423F' }}>
              {' '}
              Warning!
            </Typography>
            Please ensure that you send the correct value. It's strictly
            required not to be less than calculated. You can check the
            derivated wallet balance, it must match the exact deposit amount.
            Otherwise, the money will be locked forever!
          </Typography>
        </Box>
      </SectionPaper>
      {isDevMode && (
        <SectionPaper heading="Dev Info">
          <SectionContainer spacing={2}>
            {acceptedQuote && (
              <JsonPaper title="Accepted PegIn quote" value={acceptedQuote} />
            )}
            {quote && <JsonPaper title="PegIn quote" value={quote} />}
          </SectionContainer>
        </SectionPaper>
      )}
    </>
  );
}

export default DepositPegIn;
