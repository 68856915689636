import { Box, useTheme, styled } from '@mui/material';
import FlyImage from './FlyImage';
import rocket from '../../images/arrows.svg';

const Square = styled(Box)({
  position: 'relative',
  '&::before': {
    content: '""',
    display: 'block',
    paddingTop: '100%',
  },
});

function RocketMosaic() {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  return (
    <Box
      sx={{
        height: '100%',
        marginTop: '1rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
          gridTemplateRows: '2fr 2fr 1fr 1fr 1fr',
          gridTemplateAreas: `
          "a1 a1 . . ."
          ". . a2 a2 ."
          ". a5 . . a6"
          "a7 . a8 a8 ."
          ". . a8 a8 a9"
          `,
        }}
      >
        <Square gridArea="a1" bgcolor={theme.flyOver.panPurple} />
        <Square
          sx={{
            gridArea: 'a2',
            backgroundColor: isLight
              ? theme.flyOver.patence
              : theme.flyOver.white,
          }}
        >
          <FlyImage
            src={rocket}
            alt="Rocket"
            sx={{
              position: 'absolute',
              padding: '5%',
              top: 0,
              left: 0,
              backgroundColor: isLight
                ? theme.flyOver.patence
                : theme.flyOver.white,
            }}
          />
        </Square>
        <Square gridArea="a5" bgcolor={theme.flyOver.patence} />
        <Square gridArea="a6" bgcolor={theme.flyOver.panPurple} />
        <Square gridArea="a7" bgcolor={theme.flyOver.patence} />
        <Square gridArea="a8" bgcolor={theme.flyOver.panPurple} />
        <Square gridArea="a9" bgcolor={theme.flyOver.white} />
      </Box>
    </Box>
  );
}

export default RocketMosaic;
