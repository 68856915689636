import {
  useState,
  useMemo,
  useCallback,
  useRef,
  createContext,
  ReactNode,
} from 'react';

interface ITimer {
  time: number;
  totalSeconds: number;
  runTimer: () => void;
  resetTimer: () => void;
  setTimer: (seconds: number) => void;
}

const defaultTime = 100;
const defaultTimerValue: ITimer = {
  time: defaultTime,
  totalSeconds: defaultTime,
  runTimer: () => console.warn("runTimer not implemented."),
  resetTimer: () => console.warn("resetTimer not implemented."),
  setTimer: (seconds: number) => console.warn(`setTimer not implemented. Received seconds: ${seconds}`),
};


export const TimerContext = createContext<ITimer>(defaultTimerValue);

interface TimerProviderProps {
  children: ReactNode;
}

export function TimerProvider({ children }: TimerProviderProps) {
  const interval = useRef<number | undefined>();

  const [totalSeconds, setTotalSeconds] = useState(defaultTime);
  const [time, setTime] = useState<number>(defaultTime);

  const runTimer = useCallback(() => {
    if (interval.current !== undefined) return;
    interval.current = window.setInterval(() => {
      setTime((oldTime) => {
        if (oldTime > 0) return oldTime - 1;
        clearInterval(interval.current as number);
        interval.current = undefined;
        return oldTime;
      });
    }, 1000);
  }, []);

  const resetTimer = useCallback(() => {
    if (interval.current !== undefined) {
      clearInterval(interval.current);
      interval.current = undefined;
    }
    setTime(totalSeconds);
  }, [totalSeconds]);

  const setTimer = useCallback((seconds: number) => {
    setTime(seconds);
    setTotalSeconds(seconds);
  }, []);

  const value = useMemo(() => ({
    time,
    totalSeconds,
    runTimer,
    resetTimer,
    setTimer,
  }), [time, totalSeconds, runTimer, resetTimer, setTimer]);
  return (
    <TimerContext.Provider value={value}>{children}</TimerContext.Provider>
  );
}

export default TimerProvider;
