export enum Rsk {
  Mainnet = 30,
  Testnet = 31,
  Regtest = 33,
  Development = 31,
}

export enum Path {
  all = '*',
  default = '/',
  connect = '/connect',
  getQuote = '/getQuote',
  accept = '/accept',
  deposit = '/deposit',
  userQuotes = '/userQuotes',
}

export enum PegMode {
  PegIn = 'PegIn',
  PegOut = 'PegOut',
}

export interface MetamaskError {
  error?: string;
}
