import { SwitchProps } from '@mui/material';
import FlySwitch from './FlySwitch';
import devInfoImage from '../../images/dev-info.svg';
import useActions from '../../hooks/useActions';
import useStore from '../../hooks/useStore';

function DevInfoSwitch(props: SwitchProps) {
  const { toggleDevInfo } = useActions();
  const {
    ui: { isDevMode },
  } = useStore();
  return (
    <FlySwitch
      {...props}
      checked={isDevMode}
      onChange={() => toggleDevInfo()}
      switchcolor="devInfo"
      thumbimage={{ checked: devInfoImage, unchecked: devInfoImage }}
    />
  );
}

export default DevInfoSwitch;
