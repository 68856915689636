import { LiquidityProvider, Quote, PegoutQuote } from '@rsksmart/flyover-sdk';

export function assertIsAccount(
  account: string | undefined,
): asserts account is string {
  if (!account) throw new Error('Web3 provider is not connected');
}
export function assertIsLp(
  lp: LiquidityProvider | undefined,
): asserts lp is LiquidityProvider {
  if (!lp) throw new Error('Liquidity provided is not selected');
}
export function assertIsQuote(
  quote: Quote | PegoutQuote | undefined,
): asserts quote is Quote | PegoutQuote {
  if (!quote) throw new Error('Cannot get quotes');
}

const LEGACY_MAINNET_REGEX = /^[1]([a-km-zA-HJ-NP-Z1-9]{25,34})$/;
const LEGACY_TESTNET_REGEX = /^[mn]([a-km-zA-HJ-NP-Z1-9]{25,34})$/;
const P2SH_MAINNET_REGEX = /^[3]([a-km-zA-HJ-NP-Z1-9]{25,34})$/;
const P2SH_TESTNET_REGEX = /^[2]([a-km-zA-HJ-NP-Z1-9]{25,34})$/;

export function assertIsValidBtcAddress(address: string, chainId: number) {
  if (!address) {
      return false;
  }

  switch (chainId) {
      case 30: // Mainnet
          return LEGACY_MAINNET_REGEX.test(address) || P2SH_MAINNET_REGEX.test(address);
      case 31: // Testnet
          return LEGACY_TESTNET_REGEX.test(address) || P2SH_TESTNET_REGEX.test(address);
      default:
          return false;
  }
}
