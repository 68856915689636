import { useLocation } from 'react-router-dom';
import { AcceptedPegoutQuote, PegoutQuote } from '@rsksmart/flyover-sdk';
import { Box, Typography, Link } from '@mui/material';
import SectionContainer from '../components/UI/SectionContainer';
import SectionPaper from '../components/UI/SectionPaper';
import JsonPaper from '../components/UI/JsonPaper';
import useStore from '../hooks/useStore';
import { LocationState } from './AcceptPegOutQuote';
import { Rsk } from '../types';

const explorerUrls: Record<Rsk, string> = {
  [Rsk.Mainnet]: 'https://explorer.rsk.co/tx/',
  [Rsk.Testnet]: 'https://explorer.testnet.rsk.co/tx/',
  [Rsk.Regtest]: 'http://localhost:4444/tx/',
};

function DepositPegOut() {
  const location = useLocation();
  const state = location.state as LocationState;
  const {
    flyover,
    ui: { isDevMode },
    web3: { chainId },
  } = useStore();
  const quote = flyover.quoteReturn[0] as PegoutQuote | undefined;
  const acceptedQuote = flyover.acceptedQuote as AcceptedPegoutQuote;
  const href = explorerUrls[chainId ?? Rsk.Testnet] + state?.txHash ?? '';

  return (
    <>
      <SectionPaper heading="Deposit Funds">
        {state?.txHash && (
          <Box
            sx={{
              display: 'flex',
              gap: '2rem',
              flexDirection: isDevMode ? 'column' : 'row',
              justifyContent: 'center',
              '@media (max-width: 1000px)': {
                flexDirection: 'row',
              },
              alignItems: isDevMode ? 'center' : 'flex-start',
              '@media (max-width: 800px)': {
                flexDirection: 'column',
                alignItems: 'center',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="h4">Thank you!</Typography>
              <Typography>
                <Link href={href} target="blank">
                  Your transaction
                </Link>{' '}
                has been executed.
              </Typography>
              <Typography>
                The operation will be completed within 5-10 minutes.
              </Typography>
            </Box>
          </Box>
        )}
      </SectionPaper>
      {isDevMode && (
        <SectionPaper heading="Dev Info">
          <SectionContainer spacing={2}>
            {acceptedQuote && (
              <JsonPaper title="Accepted PegOut quote" value={acceptedQuote} />
            )}
            {quote && <JsonPaper title="PegOut quote" value={quote} />}
          </SectionContainer>
        </SectionPaper>
      )}
    </>
  );
}

export default DepositPegOut;
