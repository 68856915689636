import { createSlice } from '@reduxjs/toolkit';
import { Rsk } from '../../types';
import { login } from './web3.actions';
import flyover from '../../flyover';

interface Web3Slice {
  chainId?: Rsk;
  account?: string;
  loading: boolean;
  errorMessage: string;
}

const initialState: Web3Slice = {
  account: '',
  loading: false,
  errorMessage: '',
};

export const web3Slice = createSlice({
  name: 'web3',
  initialState,
  reducers: {
    logout: (state) => {
      state.account = '';
      state.chainId = undefined;
      state.loading = false;
      state.errorMessage = '';
      flyover.disconnectFromRsk();
      window.location.reload();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.account = payload?.account;
        state.chainId = payload?.chainId;
      })
      .addCase(login.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload as string;
      });
  },
});
