import { Stack } from '@mui/material';
import { PegMode } from '../../types';
import PegInOutButton from './PegInOutButton';
import useStore from '../../hooks/useStore';
import useActions from '../../hooks/useActions';

function PegInOutButtons() {
  const {
    flyover: { pegMode },
  } = useStore();
  const { setPegMode } = useActions();
  return (
    <Stack direction="row" justifyContent="center" spacing={2}>
      <PegInOutButton
        mode={PegMode.PegIn}
        isactive={+(pegMode === PegMode.PegIn)}
        onClick={() => setPegMode(PegMode.PegIn)}
      >
        BTC to <br /> RBTC
      </PegInOutButton>
      <PegInOutButton
        mode={PegMode.PegOut}
        isactive={+(pegMode === PegMode.PegOut)}
        onClick={() => setPegMode(PegMode.PegOut)}
      >
        RBTC <br /> to BTC
      </PegInOutButton>
    </Stack>
  );
}

export default PegInOutButtons;
