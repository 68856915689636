import { Card, CardProps, styled } from '@mui/material';

const StyledCard = styled(Card)(({ theme }) => ({
  padding: '0.8rem',
  backgroundColor:
    theme.palette.mode === 'light'
      ? theme.flyOver.white
      : theme.flyOver.ecliple,
  borderRadius: '10px',
  overflow: 'auto',
}));

function InfoCardPaper({ children, ...rest }: CardProps) {
  return (
    <StyledCard elevation={0} {...rest}>
      {children}
    </StyledCard>
  );
}

export default InfoCardPaper;
