import type RLogin from '@rsksmart/rlogin';
import { trezorProviderOptions } from '@rsksmart/rlogin-trezor-provider';
import { ledgerProviderOptions } from '@rsksmart/rlogin-ledger-provider';
import { dcentProviderOptions } from '@rsksmart/rlogin-dcent-provider';
import Portis from '@portis/web3';
import { WalletConnect2Provider } from '@rsksmart/rlogin-walletconnect2-provider';
import { Rsk } from './types';

const rpcUrlString = process.env.REACT_APP_RPC_URL;
if (!rpcUrlString) {
  throw new Error('Environment variable REACT_APP_RPC_URL is not defined.');
}

const rpcUrls: Record<number, string> = {};
const infoOptions: Record<number, { addressBaseURL: string }> = {};
const chainId = Rsk[process.env.REACT_APP_ENV as keyof typeof Rsk];
export type Options = NonNullable<ConstructorParameters<typeof RLogin>[number]>;
export const rLoginOptions: Options = {
  providerOptions: {
    walletconnect: {
      package: WalletConnect2Provider,
      options: {
        projectId: process.env.REACT_APP_PROJECTID_WALLET2CONNECT,
        chains: chainId,
        showQrModal: true,
        rpcMap: rpcUrls,
      },
    },
    portis: {
      package: Portis,
      options: {
        id: process.env.REACT_APP_PROJECTID_PORTIS,
        network: {
          nodeUrl: rpcUrlString,
          chainId: chainId,
        },
      },
    },
    'custom-ledger': ledgerProviderOptions,
    'custom-dcent': dcentProviderOptions,
    'custom-trezor': {
      ...trezorProviderOptions,
      options: {
        manifestEmail: 'info@iovlabs.org',
        manifestAppUrl: process.env.REACT_APP_URL,
      },
    },
  },
  rpcUrls,
  supportedChains: [chainId],
  infoOptions,
};
