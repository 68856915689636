import React from 'react';
import { Snackbar } from '@mui/material';
import FlyAlert from './FlyAlert';

interface FlyErrorProps {
  errorMessage: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}

function FlyError({ errorMessage, setErrorMessage }: FlyErrorProps) {
  return (
    <>
      {errorMessage && (
        <Snackbar
          open={!!errorMessage}
          onClose={() => setErrorMessage('')}
          autoHideDuration={10000}
        >
          <FlyAlert severity="error">{errorMessage}</FlyAlert>
        </Snackbar>
      )}
    </>
  );
}

export default FlyError;
