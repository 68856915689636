import { useEffect } from 'react';
import useActions from './useActions';
import flyover from '../flyover';
import useStore from './useStore';

export function useLpList() {
  const { setLpList } = useActions();
  const {
    web3: { chainId },
  } = useStore();

  useEffect(() => {
    (async () => {
      try {
        if (!chainId) return;
        const lpList = await flyover.getLiquidityProviders();
        setLpList(lpList);
      } catch (error) {
        // do nothing
      }
    })();
  }, [setLpList, chainId]);
}

export default useLpList;
