import { configureStore } from '@reduxjs/toolkit';
import { flyoverSlice } from './flyover/flyover.slice';
import { web3Slice } from './web3/web3.slice';
import { uiSlice } from './ui/ui.slice';

export const store = configureStore({
  reducer: {
    flyover: flyoverSlice.reducer,
    web3: web3Slice.reducer,
    ui: uiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['flyover/setQuoteReturn'],
        ignoredPaths: ['flyover'],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
