import { useState, createRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Quote } from '@rsksmart/flyover-sdk';
import { Path } from '../types';
import { assertIsQuote } from '../types/assertions';
import SectionContainer from '../components/UI/SectionContainer';
import SectionPaper from '../components/UI/SectionPaper';
import InfoCard from '../components/UI/InfoCard';
import FlyButton from '../components/UI/FlyButton';
import Segue from '../components/UI/Segue';
import JsonPaper from '../components/UI/JsonPaper';
import FlyError from '../components/UI/FlyError';
import useStore from '../hooks/useStore';
import useActions from '../hooks/useActions';
import flyover from '../flyover';
import ReCAPTCHA from 'react-google-recaptcha';
import { ethers } from '@rsksmart/bridges-core-sdk';
import { roundUp } from './Connect';

function AcceptQuotePegIn() {
  const navigate = useNavigate();
  const {
    flyover: { quoteReturn, lpIndex, lpList },
    ui: { isDevMode },
  } = useStore();
  const { setAcceptedQuote, setCaptchaToken } = useActions();

  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const quote = quoteReturn[0] as Quote | undefined;

  const recaptchaRef = createRef<ReCAPTCHA>();

  const acceptQuote = async () => {
    setLoading(true);
    recaptchaRef.current?.execute();
  };
  
  async function onChange(token: string | null) {
    if (!token) {
      setLoading(false);
      setErrorMessage('No captcha token resolved');
      return;
    }
    try {
      assertIsQuote(quote);
      setCaptchaToken(token);
      const acceptedQuote = await flyover.acceptQuote(quote);
      setAcceptedQuote(acceptedQuote);
      navigate(Path.deposit);
    } catch (error) {
      setErrorMessage(
        error instanceof Error
          ? `AcceptPegInQuote: ${error.message}`
          : 'AcceptPegInQuote: Cannot accept quote',
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={lpList[lpIndex].siteKey}
        onChange={onChange}
      />
      <SectionPaper heading="Accept a quote">
        {quote && (
          <SectionContainer spacing={2}>
            <InfoCard
              title="Amount to deposit (BTC) = Amount to deposit + Call Fee"
              message={roundUp(
                ethers.utils.formatEther(
                  BigInt(quote?.quote?.callFee ?? 0) + BigInt(quote?.quote?.value ?? 0) + BigInt(quote?.quote?.gasFee ?? 0) + BigInt(quote?.quote?.productFeeAmount?? 0)
              )).toString()}
              hasCopy
            />
            <InfoCard
              title="Number of block confirmations"
              message={quote?.quote?.confirmations?.toString() ?? '0'}
              hasCopy
            />
            <InfoCard title="Quote Hash" message={quote?.quoteHash} hasCopy />
            <FlyButton onClick={acceptQuote}>Accept Quote & Continue</FlyButton>
          </SectionContainer>
        )}
      </SectionPaper>
      {isDevMode && (
        <SectionPaper heading="Dev Info">
          {quote && (
            <SectionContainer>
              <JsonPaper title="PegIn quote" value={quote} />
            </SectionContainer>
          )}
        </SectionPaper>
      )}
      <FlyError errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
      <Segue open={loading} />
    </>
  );
}

export default AcceptQuotePegIn;
