import { Network } from '@rsksmart/bridges-core-sdk';
import { Flyover } from '@rsksmart/flyover-sdk';

const tokenResolver = function () {
  return import('./store/store').then(
    (mod) => mod.store.getState().flyover.captchaToken,
  );
};

const flyover = new Flyover({
  network: process.env.REACT_APP_ENV as Network,
  captchaTokenResolver: tokenResolver,
  allowInsecureConnections: true,
});

export default flyover;
