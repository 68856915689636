import { styled, Switch } from '@mui/material';

type SwitchColorModes = 'devInfo' | 'colorMode';

interface IFlySwitchProps {
  switchcolor: SwitchColorModes;
  thumbimage: {
    checked: string;
    unchecked: string;
  };
}

const FlySwitch = styled(Switch)<IFlySwitchProps>(
  ({ theme, switchcolor: colormode, thumbimage }) => {
    const isLight = theme.palette.mode === 'light';
    return {
      width: 64,
      height: 32,
      padding: 0,
      '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translate(3px, 2px)',
        '&.Mui-checked': {
          transform: 'translate(33px, 2px)',
          '& .MuiSwitch-thumb:before': {
            backgroundImage: `url(${thumbimage.checked})`,
          },
          '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.flyOver.gorgonzola,
          },
        },
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: {
          devInfo: theme.flyOver.whiteSmoke,
          colorMode: isLight
            ? theme.flyOver.superSilver
            : theme.flyOver.byzantine,
        }[colormode],
        width: 26,
        height: 26,
        '&:before': {
          content: "''",
          position: 'absolute',
          width: '100%',
          height: '100%',
          left: 0,
          top: 0,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url(${thumbimage.unchecked})`,
        },
      },
      '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: {
          devInfo: isLight
            ? theme.flyOver.gainsboro
            : theme.flyOver.shadowMountain,
          colorMode: theme.flyOver.gainsboro,
        }[colormode],
        borderRadius: 999,
      },
    };
  },
);

export default FlySwitch;
