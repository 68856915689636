import { Routes, Route, Navigate } from 'react-router-dom';
import { Path, PegMode } from './types';
import Connect from './screens/Connect';
import GetQuotePegIn from './screens/GetQuotePegIn';
import GetQuotePegOut from './screens/GetQuotePegOut';
import AcceptQuotePegIn from './screens/AcceptPegInQuote';
import AcceptQuotePegOut from './screens/AcceptPegOutQuote';
import DepositPegIn from './screens/DepositPegIn';
import DepositPegOut from './screens/DepositPegOut';
import PageContainer from './components/PageContainer';
import UserQuotes from './screens/UserQuotes';
import useStore from './hooks/useStore';
import useLpList from './hooks/useLpList';

function App() {
  const { flyover, web3 } = useStore();
  const { account } = web3;
  useLpList();
  if (!account)
    return (
      <Routes>
        <Route path={Path.all} element={<Navigate to={Path.default} />} />
        <Route path={Path.default} element={<Connect />} />
      </Routes>
    );
  else
    return (
      <>
        <PageContainer>
          <Routes>
            <Route path={Path.all} element={<Navigate to={Path.getQuote} />} />
            <Route path={Path.userQuotes} element={<UserQuotes />} />
            {flyover.pegMode === PegMode.PegIn ? (
              <>
                <Route path={Path.getQuote} element={<GetQuotePegIn />} />
                <Route path={Path.deposit} element={<DepositPegIn />} />
                <Route path={Path.accept} element={<AcceptQuotePegIn />} />
              </>
            ) : (
              <>
                <Route path={Path.getQuote} element={<GetQuotePegOut />} />
                <Route path={Path.deposit} element={<DepositPegOut />} />
                <Route path={Path.accept} element={<AcceptQuotePegOut />} />
              </>
            )}
          </Routes>
        </PageContainer>
      </>
    );
}

export default App;
