import { Dispatch, SetStateAction } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';

interface GoBackDialogProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  goBack: () => void;
}
function GoBackDialog({ open, setOpen, goBack }: GoBackDialogProps) {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Logout</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are about to close the app. Are you sure you want to start over?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={goBack}>Exit App</Button>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

export default GoBackDialog;
