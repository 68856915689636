import { styled, Button, ButtonProps } from '@mui/material';

const FlyButton = styled(Button)<ButtonProps>(({ theme }) => ({
  padding: '0.8rem 2.5rem',
  borderRadius: '999rem',
  color: theme.flyOver.white,
  fontFamily: 'IBM-Plex-semibold',
  fontWeight: '500',
  backgroundColor: theme.flyOver.panPurple,
  '&:hover': {
    backgroundColor: theme.flyOver.panPurple,
  },
  textTransform: 'none',
}));

export default FlyButton;
