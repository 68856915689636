import { useMemo } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { flyoverSlice } from '../store/flyover/flyover.slice';
import { web3Slice } from '../store/web3/web3.slice';
import { uiSlice } from '../store/ui/ui.slice';
import { login } from '../store/web3/web3.actions';
import { loadUserQuotes } from '../store/flyover/flyover.actions';
import { useDispatch } from 'react-redux';
import type { AppDispatch } from '../store/store';

const rootActions = {
  ...flyoverSlice.actions,
  ...web3Slice.actions,
  ...uiSlice.actions,
  login,
  loadUserQuotes,
};

const useAppDispatch: () => AppDispatch = useDispatch;

function useActions() {
  const dispatch = useAppDispatch();
  return useMemo(() => bindActionCreators(rootActions, dispatch), [dispatch]);
}

export default useActions;
