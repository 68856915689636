import { ReactNode } from 'react';
import { Box, Tooltip } from '@mui/material';
import Header from './Header';
import Steps from './Steps';
import Footer from './Footer';
import DevInfoSwitch from './UI/DevInfoSwitch';
import Layout from './Layout';

interface IPageContainer {
  children: ReactNode;
}

function PageContainer({ children }: IPageContainer) {
  return (
    <Layout
      sx={{
        padding: '1rem 1rem 5rem 1rem',
      }}
    >
      <Header />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          '@media (max-width: 1000px)': {
            flexDirection: 'column',
          },
          gap: '1rem',
          position: 'relative',
        }}
      >
        <Tooltip title="Show server response">
          <Box
            sx={{
              position: 'absolute',
              right: '1.5rem',
              top: '1.5rem',
            }}
          >
            <DevInfoSwitch
              sx={{
                position: 'absolute',
                right: '1.5rem',
                top: '2rem',
              }}
            />
          </Box>
        </Tooltip>
        <Steps />
        {children}
      </Box>
      <Footer />
    </Layout>
  );
}

export default PageContainer;
